'use client'
import React, { useState, useEffect, useContext } from 'react'
import { Nav } from './nav'
import { ChevronRight, LayoutDashboard, MessageCircle, Rss, User } from 'lucide-react'
import { Button } from './ui/button'
import { LoginContext } from '@/Context/LoginContext'
import { usePathname } from 'next/navigation'
type Props = {}

export default function Sidebar({}: Props) {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [onlyWidth, setOnlyWidth] = useState<number | null>(null)
  const [mobileWidth, setMobileWidth] = useState<boolean | null>(null)
  const { isAuthenticated } = useContext(LoginContext)
  const pathname = usePathname()
  useEffect(() => {
    const handleResize = () => {
      setOnlyWidth(window.innerWidth)
      setMobileWidth(window.innerWidth < 768)
    }

    handleResize() // Set initial values

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  function toggleSidebar() {
    setIsCollapsed(!isCollapsed)
  }

  if (onlyWidth === null || mobileWidth === null) {
    // Return null or a loader if you want to wait for the window object
    return null
  }

  return pathname !== '/' ? (
    <div className="relative min-w-[80px] border-r px-3 pb-10 pt-24">
      {!mobileWidth && (
        <div className="absolute right-[-20px] top-7">
          <Button variant="secondary" className="rounded-full p-2" onClick={toggleSidebar}>
            <ChevronRight />
          </Button>
        </div>
      )}
      <Nav
        isCollapsed={mobileWidth ? true : isCollapsed}
        links={[
          {
            title: 'home',
            href: '/home',
            icon: LayoutDashboard,
            variant: 'default',
          },
          {
            title: 'Submissions',
            href: '/submissions',
            icon: LayoutDashboard,
            variant: 'default',
          },
          {
            title: 'Prompt Management',
            href: '/prompt',
            icon: MessageCircle,
            variant: 'ghost',
          },
          {
            title: 'Users',
            href: '/users',
            icon: User,
            variant: 'ghost',
          },
          {
            title: 'NiteShift',
            href: '/admin',
            icon: Rss,
            variant: 'ghost',
          },
        ]}
      />
    </div>
  ) : null
}
