import { createContext, useState, useContext, ReactNode } from 'react'

// Create the LoginContext with a default value
const LoginContext = createContext({
  isAuthenticated: false,
  setAuthenticated: (value: boolean) => {},
})

// AuthenticationProvider component that provides the context
const AuthenticationProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setAuthenticated] = useState(false)

  return (
    <LoginContext.Provider value={{ isAuthenticated, setAuthenticated }}>
      {children}
    </LoginContext.Provider>
  )
}

export { LoginContext, AuthenticationProvider }
