'use client'
import React from 'react'
import './globals.scss'
import { Inter } from 'next/font/google'
import Sidebar from '@/components/sidebar'
import { cn } from '@/lib/utils'
import { Toaster } from '@/components/ui/sonner'
import { AuthenticationProvider } from '@/Context/LoginContext'

const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
})

/* Our app sits here to not cause any conflicts with payload's root layout  */
const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <html lang="en">
      <body
        className={cn('min-h-screen w-full bg-white text-black flex', inter.className, {
          'debug-screens': process.env.NODE_ENV === 'development',
        })}
      >
        <AuthenticationProvider>
          <Sidebar />
          {/* Sidebar */}
          {/* main page */}

          <div className="p-8 w-full">{children}</div>

          <Toaster position="top-right" />
        </AuthenticationProvider>
      </body>
    </html>
  )
}

export default Layout
